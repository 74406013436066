// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".sAXo{padding-top:1.6rem}.iUvz{font-size:1.6rem;font-size:var(--font-size-menu,1.6rem);color:#373737;margin-bottom:1.6rem}.iUvz:after,.iUvz:before{content:\"“\"}._1LeX{width:100%;justify-content:space-between}._1LeX,.AF0-{flex-direction:column}._2eF3,._1LeX,.AF0-{display:flex;align-items:center}._2eF3{margin-bottom:1.6rem}@media (min-width:48em){._2eF3{min-height:4.8rem}}@media (min-width:48em)and (max-width:61.9375em){._2eF3{font-size:3rem}}._2eF3 svg{margin:0 .9rem}._1DKV{height:90px;display:flex;align-items:center;margin-bottom:.8rem}@media (min-width:48em)and (max-width:61.9375em){._1DKV{height:70px}}._1DKV img{width:auto;max-height:100%}._1dJ2{padding-top:0}._1dJ2 ._1LeX{padding-top:1.6rem;padding-bottom:2.4rem}@media (min-width:48em)and (max-width:61.9375em){._1dJ2 ._1LeX{font-size:3rem}}@media (max-width:47.9375em){._1dJ2 ._1LeX:nth-child(2n){background-color:#fcd700}}@media (min-width:48em){._1dJ2 ._1LeX:nth-child(6n+4),._1dJ2 ._1LeX:nth-child(6n+5),._1dJ2 ._1LeX:nth-child(6n+6){background-color:#fcd700}}", ""]);
// Exports
exports.locals = {
	"testimonialsBox": "sAXo",
	"text": "iUvz",
	"review": "_1LeX",
	"reviewRow": "AF0-",
	"rating": "_2eF3",
	"reviewImg": "_1DKV",
	"testimonialsList": "_1dJ2"
};
module.exports = exports;
