// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._5B6P{text-align:center;padding:2.4rem 0 5.6rem}._28qx{margin-bottom:2.4rem}._3lt-{color:#000;position:relative}._3lt-:after{content:\"\";width:90%;height:.1rem;background:#000;position:absolute;bottom:0;left:50%;transform:translateX(-50%)}", ""]);
// Exports
exports.locals = {
	"testimonials": "_5B6P",
	"header": "_28qx",
	"link": "_3lt-"
};
module.exports = exports;
