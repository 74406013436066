























import Vue, { PropType } from 'vue'
import UiTestimonialBox from './UiTestimonialBox'
import { TestimonialBoxData } from './UiTestimonials.types'

export default Vue.extend({
  name: 'UiTestimonials',
  components: {
    UiTestimonialBox
  },
  props: {
    testimonialsData: {
      type: Object as PropType<TestimonialBoxData>,
      required: true
    },
    header: {
      type: String,
      default: ''
    },
    headerTag: {
      type: String,
      default: ''
    },
    linkLabel: {
      type: String,
      default: ''
    },
    linkUrl: {
      type: String,
      default: ''
    }
  }
})
