var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'container',
    _vm.$style.testimonialsBox,
    ( _obj = {}, _obj[_vm.$style.testimonialsList] = _vm.isTestimonialsList, _obj )
  ]},[_c('div',{class:['row']},_vm._l((_vm.testimonialsData.testimonials),function(testimonial,index){return _c('div',{key:index,class:['col-md-4', _vm.$style.review]},[_c('div',{class:_vm.$style.reviewRow},[_c('p',{class:['h2', _vm.$style.rating]},[(testimonial.rating)?_c('UiIcon',{attrs:{"name":"star","height":"22"}}):_vm._e(),_vm._v("\n          "+_vm._s(testimonial.rating)+"\n          "),(testimonial.rating)?_c('UiIcon',{attrs:{"name":"star","height":"22"}}):_vm._e()],1),_vm._v(" "),_c('p',{class:_vm.$style.text},[_vm._v("\n          "+_vm._s(testimonial.quote)+"\n        ")])]),_vm._v(" "),_c('div',{class:_vm.$style.reviewRow},[(testimonial.logo && testimonial.logo.properties.link)?_c('UiLink',{class:_vm.$style.reviewImg,attrs:{"to":testimonial.logo.properties.link,"target":"_blank","rel":"noopener"}},[_c('UiImage',{attrs:{"image":{
              publicUrl: testimonial.logo.publicUrl,
              alt: testimonial.logo.properties.title
            }}})],1):_c('span',{class:_vm.$style.reviewImg},[(testimonial.logo)?_c('UiImage',{attrs:{"image":{
              publicUrl: testimonial.logo.publicUrl,
              alt: testimonial.logo.properties.title
            }}}):_vm._e()],1),_vm._v(" "),(testimonial.url)?_c('UiLink',{staticClass:"btn btn--m btn--dark",attrs:{"to":testimonial.url,"target":testimonial.newWindow ? '_blank' : '',"rel":testimonial.newWindow ? 'noopener' : ''}},[_vm._v("\n          "+_vm._s(_vm.testimonialsData.i18n.btnLabel)+"\n        ")]):_vm._e()],1)])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }