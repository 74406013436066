














































































import Vue, { PropType } from 'vue'
import UiIcon from '../../UiIcon'
import UiImage from '../../UiImage'
import { TestimonialBoxData } from '../UiTestimonials.types'

export default Vue.extend({
  name: 'UiTestimonialBox',
  components: {
    UiIcon,
    UiImage
  },
  props: {
    testimonialsData: {
      type: Object as PropType<TestimonialBoxData>,
      required: true
    }
  },
  computed: {
    isTestimonialsList (): boolean {
      return this.testimonialsData.testimonials?.length > 4
    }
  }
})
